
import {defineComponent, ref, onMounted} from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import PriceListModal from "@/components/modals/PriceListModal.vue";
import BaseIcon from '@/components/ui/BaseIcon.vue';
import router from "@/router";

export default defineComponent({
  name: 'PriceSearchPage',
  components: {
    SearchDropdown,
    PriceListModal,
    BaseIcon
  },
  data: () => ({}),
  setup() {
    const viewedArticles = ref([]);

    function update(item: any) {
      if (!!item) router.push({name: 'price_article', params: {id: item.id}})
    }

    function getViewedArticles() {
      let storage = JSON.parse(localStorage.getItem('VIEWED_ARTICLES')!);
      if (storage) viewedArticles.value = viewedArticles.value.concat(storage)
    }

    function goToArticle(item: any) {
      router.push({name: 'price_article', params: {id: item.articleId}})
    }

    onMounted(() => {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
        getViewedArticles()
      } else {
        router.push('login')
      }
    });

    return {viewedArticles, update, goToArticle}
  },
  methods: {}
})
