
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import MasteringSheet from "@/services/MasteringSheet"
import prices from "@/services/prices"
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import {ConvertDate} from "@/components/converters/date";
import { GetUserName } from "@/services/authentication";
import router from "@/router";
import articles from "@/services/articles";
import {roles} from "@/services/roles/serviceRoles";
import debounce from '@/components/debounce';

export default defineComponent({
  name: 'PriceListModal',
  components: { ModalWrapper, BaseCheckbox, SearchDropdown, SmallSpinner },
  data: () => ({
    masteringSheets: null as any,
    selectedMasteringSheet: null as any,
    priceInfo: {} as any,
    activeMenu: 'sheets' as string,
    menu: [
      {key: 'sheets', value: 'Листы освоения'},
      {key: 'lists', value: 'Проценка по артикулам'},
      {key: 'lists2', value: 'Проценка по списку артикулов'},
    ] as object,
    listOfArticles: [] as any,
    listOfArticles2: [] as any,
    isLoading: false as boolean,
    searchResult: [] as any,
    showResults: false as boolean,
    error: '' as string
  }),
  props: {
    tablePrice: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    convertDate() {
      return (date: any) => {
        return ConvertDate(date)
      }
    },
    getUserName() {
      return (id: number) => {
        return GetUserName(id)
      }
    },
    checkCanUserViewMasteringSheet(): any {
      return this.$store.getters.getAllPermissions.search_mastering_sheet[this.$store.getters.getUserRole]
    }
  },
  setup() {
    return {
      ...debounce(),
    }
  },
  methods: {
    async setDefaultData() {
      if (this.checkCanUserViewMasteringSheet) {
        this.masteringSheets = await MasteringSheet.getAllSheets();
      } else {
        this.menu = [{key: 'lists', value: 'Листы проценки'}]
        this.activeMenu = 'lists'
      }
      if (this.tablePrice && this.tablePrice.length) {
        this.tablePrice.forEach((article: any) => this.listOfArticles.push(article.articleFull))
      }
    },
    async getPricesForList(id: number) {
      console.log('getPricesForList');
      this.priceInfo = await prices.getPricesForMasteringSheet(id);
      console.log(this.priceInfo);
      this.$emit('setNewInfo')
    },
    checkItem(itemIndex: number) {
      this.masteringSheets.forEach((ms: any, index: number) => {
        if (index === itemIndex) {
          ms.checked = !ms.checked
          if (ms.checked) this.selectedMasteringSheet = ms
          if (!ms.checked) this.selectedMasteringSheet = null
        } else {
          ms.checked = false
        }
      })
    },
    goToSheet(id: number) {
      this.$router.push(`/price/sheet/${id}`);
      let element = document.getElementById(`modal-price-list`)!;
      element.style.display = 'none'
    },
    goToLists() {
      this.$router.push(`/price/list/${this.listOfArticles.map((a: any) => a.articleId || a.id).join('&')}`);
      let element = document.getElementById(`modal-price-list`)!;
      element.style.display = 'none'
    },
    goToLists2() {
      this.$router.push(`/price/list/${this.listOfArticles2.map((a: any) => a.articleId || a.id).join('&')}`);
      let element = document.getElementById(`modal-price-list`)!;
      element.style.display = 'none'
    },
    setActiveMenu(item: any): any {
      this.activeMenu = item.key
    },
    addToList(item: any) {
      if (!!item && !this.listOfArticles.some((article: any) => article.id === item.id)) {
        this.listOfArticles.push(item)
      }
    },
    addToArray(item: any) {
      this.listOfArticles2.push(item)
    },
    async search(item: any) {
      // error.value = '';
      console.log('Value', item.target.value)
      this.searchResult = [];
      if (item.target.value.length > 1 && item.target.value.length <= 20) {
        console.log('start search')
        try {
          this.isLoading = true;
          let result = await articles.findArticleByName(item.target.value);
          console.log('Search result', result)
          if (!!result.length) {
            this.searchResult = result.map((o: any) => o.article);
            this.showResults = true;
          }
          if (!result.length) this.error = 'Данные не найдены'
        } catch (error) {
          error.value = 'Ошибка сервера';
          throw error
        }
        this.isLoading = false;
      } else {
        // this.searchResult.value = []
      }
    },
    async searchArray(event: any) {
      this.listOfArticles2 = []
      this.error = ''
      const value = event.target.value
      if (value.trim().length) {
        let items = value.split('\n');
        this.isLoading = true

        try {
          const foundedArticles = await articles.findGroupArticlesByName(items)

          foundedArticles.map((foundedArticle: any) => {
            if (foundedArticle.id) {
              const isExist = items.findIndex((item: any) => {
                return item.toLowerCase() === foundedArticle.article.toLowerCase()
              }) >= 0

              if (isExist) {
                this.addToArray(foundedArticle)
              }
            }
          })
        } catch (error) {
          error.value = 'Ошибка сервера';
          throw error
        } finally {
          this.isLoading = false
        }
      } else {
        this.isLoading = false;
        this.listOfArticles2 = []
      }
    },
    deleteFromList(index: number) {
      this.listOfArticles.splice(index, 1)
    },
    deleteFromArray(index: number) {
      this.listOfArticles2.splice(index, 1)
    },
  },
  async mounted() {
    if (this.tablePrice && this.tablePrice) {
      this.activeMenu = 'lists'
    }
    await this.setDefaultData()
  }
})
